'use client'

import { usePathname, useRouter } from 'next/navigation'
import { useContext } from 'react'
import Button from '@/components/tokens/Button'
import Wallet from '@/contexts/WalletContext'
import { ButtonColor, ButtonVariant } from '@/lib/types'
import { logEvent } from '@/lib/utils/event'

export default function NotFound() {
  const router = useRouter()
  const pathname = usePathname()
  const { wallet } = useContext(Wallet.Context)

  const handleReturnHome = () => {
    logEvent('page_error', 'view', wallet, {
      pathname,
    })

    router.push('/')
  }

  return (
    <div className="pageContainerMargin w-full">
      <h1 className="mb-[32px]">Something went wrong.</h1>
      <Button
        color={ButtonColor.Black}
        variant={ButtonVariant.Small}
        label={'Back to Home'}
        onClick={handleReturnHome}
      />
    </div>
  )
}
